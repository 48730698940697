<template>
	<section ref="qrPanel" class="qr-section">
		<client-only>
			<div class="qr-panel">
				<form>
					<date-picker
						v-model:value="state.arrival"
						:clearable="false"
						:disabled-date="disableArrivalDates"
						value-type="format"
						format="DD-MM-YYYY"
						:placeholder="checkin"
						:lang="datepickerLang"
						@pick="pickArrival"
					/>
					<date-picker
						v-model:value="state.departure"
						:open="state.openDeparture"
						:disabled-date="disableDepartureDates"
						value-type="format"
						format="DD-MM-YYYY"
						:placeholder="checkout"
						:default-value="state.pickedArrivalDate ?? new Date()"
						:lang="datepickerLang"
						@open="state.openDeparture = true"
						@close="state.openDeparture = false"
					/>
					<book-button class="button book-now cta qr" :arrival="state.arrival" :departure="state.departure">
						{{ button }}
					</book-button>
				</form>
				<div class="booking-benefits">
					<div>
						<strong>{{ header }}</strong>
					</div>
					<div v-for="benefit in benefits" :key="benefit.ID">
						<font-awesome-icon icon="fas fa-check" size="1x" />
						<span>{{ benefit.content }}</span>
					</div>
				</div>
			</div>
		</client-only>
	</section>
</template>

<script setup>
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import datePickerTranslations from '~/config/datePicker.js';

const { locale } = useI18n();

defineProps({
	button: { type: String, default: '' },
	checkin: { type: String, default: '' },
	checkout: { type: String, default: '' },
	header: { type: String, default: '' },
	benefits: { type: Array, default: () => [] },
});

const state = reactive({
	arrival: null,
	departure: null,
	openDeparture: false,
	pickedArrivalDate: null,
});

const qrPanel = ref(null);

const datepickerLang = computed(() => datePickerTranslations[locale] ?? datePickerTranslations.en);

const pickArrival = (date) => {
	state.departure = '';
	state.pickedArrivalDate = date;
	state.openDeparture = true;
};

const disableArrivalDates = (date) => {
	const today = new Date();

	return date < today.setDate(today.getDate() - 1);
};

const disableDepartureDates = (date) => {
	if (!state.pickedArrivalDate) {
		return disableArrivalDates(date);
	}

	return date.setDate(date.getDate() - 1) <= state.pickedArrivalDate;
};

onMounted(() => {
	qrPanel.value.classList.add('mounted');
});
</script>

<style lang="scss">
.mx-btn:hover {
	color: var(--cta-color-hover) !important;
}

.mx-table-date .today {
	color: var(--cta-color-hover) !important;
}

.mx-calendar-content .cell.disabled {
	color: #ccc !important;
}

.mx-calendar-content .cell:hover {
	color: var(--cta-color) !important;
	background-color: #fef3f3 !important;
}

.mx-calendar-content .cell.active {
	color: #fff !important;
	background-color: var(--cta-color-hover) !important;
}
</style>

<style lang="scss" scoped>
.mx-datepicker {
	min-width: 225px;
}

:deep(.mx-icon-calendar) {
	right: 15px;
	color: var(--cta-color);
}

.qr-section {
	margin: 0 auto;
	position: absolute;
	bottom: 100px;
	left: 0;
	right: 0;
	padding: 0;
	max-width: 820px;
	z-index: 10;

	.qr-panel {
		padding: 25px 40px 15px;
		background: var(--secondary-background-color);
		text-align: center;
		border-radius: 85px;

		form {
			display: flex;
			gap: 15px;

			input,
			select,
			:deep(.mx-input) {
				font-size: var(--body-font-size);
				font-family: var(--body-font-family);
				height: auto;
				padding: 16px 32px;
				border: none;
				border-radius: 45px;
				background: #f2ebd7;
				font-weight: 600;
				color: var(--body-color);
			}

			.button {
				flex-grow: 1;
				line-height: 53px;
				padding: 0;
			}
		}
	}
}

.booking-benefits {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	gap: 24px;
	padding: 15px 20px 5px;
	color: #fff;
	font-size: 15px;

	div {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
	}

	svg {
		color: var(--cta-color-hover);
		margin: 0 8px 0 0;
		font-size: 18px;
	}
}

@media (max-width: 880px) {
	.qr-section {
		display: none;
	}
}
</style>
