<template>
	<page-container :page="page" :locale="locale" class="index">
		<heading-image v-if="page.headingImage">
			<hero-image type="fullscreen" :class="{ 'video-background': !!page.embedCode }">
				<template #overlay-content>
					<div v-if="defaults[locale].homepage.headingImageTitle" class="hero-content">
						<p>
							<span>{{ defaults[locale].homepage.headingImageTitle }}</span>
						</p>
						<div v-parse-links v-html="defaults[locale].homepage.headingImageContent ?? ''" />
					</div>
					<div class="slider-overlay" />
				</template>
				<template #hero>
					<div v-if="page.embedCode" class="video-foreground">
						<div>
							<iframe
								:src="`${page.embedCode}?loop=1&muted=1&badge=0&autoplay=1&controls=0&autopause=0&player_id=0`"
								frameborder="0"
								allow="autoplay; fullscreen; picture-in-picture"
								allowfullscreen
								title="Boslodges video"
							/>
						</div>
					</div>
					<picture v-else>
						<source
							v-if="page.headingImagePortraitWebp"
							:srcset="page.headingImagePortraitWebp"
							media="(max-width: 500px)"
							type="image/webp"
						/>
						<source
							v-if="page.headingImagePortrait"
							:srcset="page.headingImagePortrait"
							media="(max-width: 500px)"
						/>
						<source :srcset="page.headingImageWebp" type="image/webp" />
						<source :srcset="page.headingImage" />
						<img class="object-fit" :src="page.headingImage" :alt="page.headingImageAlt" />
					</picture>
				</template>
			</hero-image>
			<booking-form
				:benefits="defaults[locale].bookingForm.benefit"
				:button="defaults[locale].bookingForm.availability"
				:checkin="defaults[locale].bookingForm.checkin"
				:checkout="defaults[locale].bookingForm.checkout"
				:header="defaults[locale].bookingForm.benefitsContent"
			/>
		</heading-image>

		<main-content :title="page.title || page.header" :content="page.content" />

		<section v-if="defaults[locale].homepage.benefit.length" class="bookingbenefits">
			<div class="row">
				<div v-for="benefit in defaults[locale].homepage.benefit" :key="benefit.ID" class="columns column4">
					<font-awesome-icon :icon="benefit.icon" size="1x" />
					<div v-parse-links v-html="benefit.content" />
				</div>
			</div>
		</section>

		<promoblocks :items="page.promoblocks">
			<template #blocks-header>
				<div class="row blocks-header align-center">
					<div class="columns column12">
						<h2>{{ defaults[locale].homepage.sectionPromotionTitle }}</h2>
						<div v-parse-links v-html="defaults[locale].homepage.sectionPromotionContent" />
					</div>
				</div>
			</template>
		</promoblocks>

		<contentblocks :items="page.contentblocks">
			<template #blocks-header>
				<div class="row blocks-header align-center">
					<div class="columns column12">
						<h2>{{ defaults[locale].homepage.sectionContentblocksTitle }}</h2>
						<div v-parse-links v-html="defaults[locale].homepage.sectionContentblocksContent" />
					</div>
				</div>
			</template>
		</contentblocks>

		<section class="gallery">
			<div class="row">
				<div class="columns column6">
					<picture>
						<source
							v-if="defaults[locale].homepage.galleryImageBigWebp"
							:srcset="defaults[locale].homepage.galleryImageBigWebp"
							media="(max-width: 500px)"
							type="image/webp"
						/>
						<source
							v-if="defaults[locale].homepage.galleryImageBig"
							:srcset="defaults[locale].homepage.galleryImageBig"
							media="(max-width: 500px)"
						/>
						<source :srcset="defaults[locale].homepage.galleryImageBigWebp" type="image/webp" />
						<source :srcset="defaults[locale].homepage.galleryImageBig" />
						<img
							class="object-fit"
							loading="lazy"
							:src="defaults[locale].homepage.galleryImageBig"
							:alt="defaults[locale].homepage.galleryImageBigAlt"
						/>
					</picture>
				</div>
				<div class="columns column6">
					<picture v-for="image in defaults[locale].homepage.gallery" :key="image.ID">
						<source
							v-if="image.imageWebp"
							:srcset="image.imageWebp"
							media="(max-width: 500px)"
							type="image/webp"
						/>
						<source v-if="image.image" :srcset="image.image" media="(max-width: 500px)" />
						<source :srcset="image.imageWebp" type="image/webp" />
						<source :srcset="image.image" />
						<img class="object-fit" loading="lazy" :src="image.image" :alt="image.imageAlt" />
					</picture>
				</div>
			</div>
		</section>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();

if (!defaults.value) {
	await fetchDefaults();
}
</script>

<style lang="scss" scoped>
.video-background {
	background-image: url('~/assets/images/poster.jpg');
	background-size: cover;
}

.video-foreground {
	position: absolute;
	top: 0;
	left: -150%;
	width: 400%;
	height: 130%;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
	}
}

.slider-overlay {
	position: absolute;
	inset: 0;
	z-index: 3;
	background: rgb(0 0 0 / 25%);
}

.intro .column8 {
	padding: 20px 60px;
}

.bookingbenefits {
	padding: 10px 0;

	.row {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: stretch;
	}

	.book-now {
		margin: 40px auto 0;
	}

	.column4 {
		text-align: center;
		background: var(--secondary-background-color);
		padding: 50px;
		border-radius: 45px;
		width: 31%;
		box-shadow: 0 34px 34px rgba(0 0 0 / 15%);

		:deep(p),
		p {
			color: #fff;
		}
	}

	svg {
		margin: 0 auto 20px;
		color: #fff;
		font-size: 40px;
	}
}

.blocks-header {
	.column12 {
		flex-flow: column wrap;
	}
}

.contentblocks {
	.blocks-header {
		p,
		:deep(p) {
			color: #fff;
		}
	}
}

.hero-content {
	position: absolute;
	max-width: 1100px;
	z-index: 10;
	left: 0;
	right: 0;
	width: auto;
	bottom: 270px;
	margin: 0 auto;
	text-align: center;
	color: #fff;
	letter-spacing: 2px;

	:deep(span) {
		color: #fff;
		font-size: 1.7em;
		font-family: var(--heading-font-family);
		font-weight: 700;
		margin: 0 0 10px;
		width: 100%;
		display: inline-block;
		line-height: 1;
	}

	:deep(p) {
		font-size: 1.8em;
	}
}

.gallery {
	.row {
		.column6 {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			align-items: center;
			padding: 0 10px;

			&:last-child {
				picture {
					width: 48%;
					margin: 0 1% 0 0;

					&:nth-child(1),
					&:nth-child(2) {
						margin: 0 1% 3% 0;
					}
				}
			}
		}
	}
}

@media (max-width: 880px) {
	.hero-content {
		bottom: 30%;

		:deep(span) {
			font-size: 1.6em;
		}

		:deep(p) {
			font-size: 1.4em;
			padding: 0 10px;
		}
	}

	.bookingbenefits {
		.column4 {
			width: 100%;
			max-width: 520px;
			margin: 20px auto;
		}
	}

	.gallery {
		.row {
			.column6 {
				&:last-child {
					picture {
						width: 49%;
						margin: 2% 0;

						&:nth-child(1),
						&:nth-child(2) {
							margin: 2% 0;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 1080px) {
	.video-foreground {
		height: 100%;
	}
}

@media (max-width: 500px) {
	.video-foreground {
		width: 470%;
	}
}
</style>
